.pagination {
  display: flex;
  list-style: none;
  li {
    padding: 0px 8px;
    &:hover {
      opacity: .5;
    }
    &:first-child {
      padding-right: 8px;
    }
    &:last-child {
      padding-left: 8px;
    }
  }
}

.active {
  color: #40DAAE;
}